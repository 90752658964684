export const environment = {
  production: true,
  apiUrl: 'https://api.bb.monkeyflux.com/api',
  appUrl: 'https://tradinghmn.com/',
  appTitle: 'HAVAS Media Network',
  version: 'VERSION',
  sentryDsn:
    'https://96fb6c05ae5644e8af058bdac328251d@o263356.ingest.sentry.io/5614153',
  amcharts: {
    chartsV4LicenseKey: 'CH377650283',
    chartsV5LicenseKey: 'AM5C377650283',
    mapsV5LicenseKey: 'AM5M377650283',
  },
  styles: {
    // Colors have to be hex
    primary_color: '#000000',
    secondary_color: '#e60000',
    white_logo: './assets/logos/havas/havas_white.png',
    black_logo: './assets/logos/havas/havas_black.png',
    company_name: 'HAVAS Media Network',
    company_url: 'https://havasmedianetwork.com/',
    favicon: 'https://www.google.com/s2/favicons?domain=havas.com',
    login_background_image: './assets/logos/making-science/login.png',
  },
  googleMapApi: 'AIzaSyALh4aNXBGiIup9eLrMK0NtrqJ5Dm7MCK0',
  noticeableProjectId: '63LWRzGg8ehYSFF4FXBL',
  noticeableWidgetId: '73ghXqHC8KZhgCEfJFmA',
  chatSpellId: '',
  syncfusionLicense:
    'ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiWX9YdH1VR2VeV0w=',
};
